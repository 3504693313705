<template>
  <b-card no-body class="order-card">
    <b-card-header header-tag="header" role="tab" class="order-card__header" v-b-toggle="identifier">
      <div class="order-card__header-block">
        <span class="label">{{ $t('order.order') }}</span>
        <span class="text">#{{order.increment_id}}</span>
      </div>
      <div class="order-card__header-block">
        <span class="label">{{ $t('order.status') }}</span>
        <span class="text">{{order.status}}</span>
      </div>
      <div class="order-card__header-block">
        <span class="label">{{ $t('order.data') }}</span>
        <span class="text">{{ createdAt }}</span>
      </div>
      <div class="order-card__header-block">
          <span class="label">{{ $t('order.tracking') }}</span>
          <div class="tracks">
            <span class="text" v-for="track in order.tracks" :key="track['track_number']">
              <a :href="'https://www.ups.com/track?trackingNumber=' + track['track_number']" target="_blank">{{ track['track_number'] }}</a>
            </span>
          </div>
      </div>
      <div class="order-card__header-block">
        <span class="label">{{ $t('order.total') }}</span>
        <span class="text">{{ order.grand_total | price($store) }}</span>
      </div>
    </b-card-header>
    <b-collapse
      :id="identifier"
      accordion="my-accordion"
      role="tabpanel"
      class="order-collapse"
    >
      <order-card-body :order="order"></order-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import moment from 'moment'
import OrderCardBody from './OrderCardBody'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    identifier: {
      type: String,
      required: true
    }
  },
  computed: {
    createdAt () {
      return moment(this.order.created_at).format('DD/MM/YYYY')
    }
  },
  components: {
    OrderCardBody
  }
}
</script>
