<template>
  <b-card class="dash-card">
    <div class="dash-card__content">
      <div class="dash-card__content-header">
        <h3 class="text-uppercase ff-futura-demi">
          {{title}}
        </h3>
      </div>
      <div class="dash-card__content-body">
        <p class="dash-card__content-body_text" v-html="body"></p>
      </div>
      <div class="dash-card__content-footer">
         <slot name="footer"></slot>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'DashCard',
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    }
  }
}
</script>
