<template>
  <b-card-body class="order-card__body">
    <div class="order-card__body_content">
      <div class="order-card__body_content_column">
        <div class="order-card__body_content_column-block column-s-a">
          <order-addresses :title="$t('order.shipping-address')" :address="shippingAddress"></order-addresses>
        </div>
      </div>
      <div class="order-card__body_content_column column-s-m">
        <div class="order-card__body_content_column-block">
          <span class="label">{{ $t('order.shipping-method') }}</span>
          <div class="block-content">
            <p class="value">{{ order.shipping_method }}</p>
            <p class="value">{{ order.shipping_description }}</p>
          </div>
        </div>
      </div>
      <div class="order-card__body_content_column column-b-a">
        <div class="order-card__body_content_column-block">
          <order-addresses :title="$t('order.billing-address')" :address="order.billing_address"></order-addresses>
        </div>
      </div>
      <div class="order-card__body_content_column column-p-m">
        <div class="order-card__body_content_column-block">
          <span class="label">{{ $t('order.payment-method') }}</span>
          <div class="block-content">
            <p class="value">{{ order.payment.method }}</p>
            <p class="value">{{ order.payment.payment_description }}</p>
          </div>
        </div>
      </div>
      <div class="order-card__body_content_column column-cta" v-if="showCta">
        <div class="order-card__body_content_column-block">
          <div class="order-cta">
            <button class="btn btn-primary order-cta_single" @click.prevent="setCurrentOrder()">{{ $t('order.view-order') }}</button>
            <button class="btn btn-primary order-cta_single" @click.prevent="reorder()" :disabled="!isReordable">{{ $t('order.reorder') }}</button>
            <router-link
              v-if="showRefound"
              :to="{ name: 'newReturns', query: { orderId: order.entity_id }}"
              class="bcm-link bcm-link-secondary t-small order-cta_single"
            >{{ $t('order.refund') }}</router-link>

          </div>
        </div>
      </div>
      <!-- <b-row>
        <b-col sm="3" md="2" class="order-card__body_content_column">
          <div class="order-card__body_content_column-block">
            <order-addresses :title="$t('order.shipping-address')" :address="shippingAddress"></order-addresses>
          </div>
        </b-col>
        <b-col sm="3" md="2" class="order-card__body_content_column">
          <div class="order-card__body_content_column-block">
            <span class="label">{{ $t('order.shipping-method') }}</span>
            <div class="block-content">
              <p class="value">{{ order.shipping_method }}</p>
              <p class="value">{{ order.shipping_description }}</p>
            </div>
          </div>
        </b-col>
        <b-col sm="3" md="2" class="order-card__body_content_column">
          <div class="order-card__body_content_column-block">
            <order-addresses :title="$t('order.billing-address')" :address="order.billing_address"></order-addresses>
          </div>
        </b-col>
        <b-col sm="3" class="order-card__body_content_column">
          <div class="order-card__body_content_column-block">
            <span class="label">{{ $t('order.payment-method') }}</span>
            <div class="block-content">
              <p class="value">{{ order.payment.method }}</p>
              <p class="value">{{ order.payment.payment_description }}</p>
            </div>
          </div>
        </b-col>
        <b-col md="3" class="order-card__body_content_column" v-if="showCta">
          <div class="order-card__body_content_column-block">
            <div class="order-cta">
              <button class="btn btn-primary order-cta_single" @click.prevent="setCurrentOrder()">{{ $t('order.view-order') }}</button>
              <router-link :to="{ name: 'home'}" class="btn btn-primary order-cta_single">{{ $t('order.reorder') }}</router-link>
              <router-link
                :to="{ name: 'home'}"
                class="bcm-link bcm-link-primary t-small order-cta_single"
              >{{ $t('order.refund') }}</router-link>
            </div>
          </div>
        </b-col>
      </b-row> -->
    </div>
  </b-card-body>
</template>

<script>
import OrderAddresses from './OrderAddresses'
import { toast } from '@/modules/notifications'
import Logger from '@/services/Logger'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    showCta: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    shippingAddress () {
      let shipping = {}
      // API
      if (this.order.extension_attributes.shipping_assignments.length) {
        shipping = this.order.extension_attributes.shipping_assignments[0].shipping.address
      }
      // GRAPHQL
      // if (this.order.shipping_info.shipping_address) {
      //   shipping = this.order.shipping_info.shipping_address
      // }
      return shipping
    },
    showRefound () {
      return this.order.status === 'complete'
    },
    isReordable () {
      return this.order.extension_attributes && this.order.extension_attributes.is_reordered === true
    }
  },
  methods: {
    setCurrentOrder () {
      this.$store.commit('orders/setOrder', this.order)
      this.$router.push({
        name: 'myOrder',
        params: { id: this.order.entity_id }
      })
    },
    reorder () {
      const _this = this
      this.$store.commit('ui/showLoader')
      this.$store.dispatch('user/reorder', { id: this.order.entity_id })
        .then(() => {
          _this.$router.push({
            name: 'cart'
          })
        })
        .catch(err => {
          toast.error(_this.$t('GenericError'))
          Logger.error(err)
        })
        .finally(() => {
          _this.$store.commit('ui/hideLoader')
        })
    }
  },
  components: {
    OrderAddresses
  }
}
</script>
