<template>
  <div role="tablist">
    <template v-for="(order, index) in orders">
      <order-card :order="order" :key="index" :identifier="`order_accordion_${index}`"></order-card>
    </template>
  </div>
</template>

<script>
import OrderCard from './OrderCard'

export default {
  props: {
    orders: {
      type: Array,
      required: true
    }
  },
  components: {
    OrderCard
  }
}
</script>
