<template>
  <div class="dash-card-list" v-if="user">
    <template v-for="(card, index) in cards">
      <dash-card :title="card.title" :body="card.content" :key="index">
        <template #footer v-for="(cta, index) in card.ctas">
          <div class="wrapp-ctas" :key="index">
            <router-link :to="{name: cta.to}" class="ff-link ff-link-primary">{{ cta.label }}</router-link>
          </div>
        </template>
      </dash-card>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import DashCard from './DashCard'

export default {
  name: 'DashCardList',
  computed: {
    user () {
      return this.$store.state.user.current
    },
    cards () {
      let cards = {}
      cards['profile'] = {
        title: this.$t('dashboard.my-profile'),
        content: `${this.user.firstname} ${this.user.lastname}<br>${this.user.email}`,
        ctas: [{
          label: this.$t('change'),
          to: 'myProfile'
        }]
      }
      cards['newsletter'] = {
        title: this.$t('dashboard.newsletter'),
        content: this.$t(`dashboard.newsletter_${(this.user.is_subscribed) ? 'yes' : 'no'}`),
        ctas: [{
          label: this.$t('change'),
          to: 'myProfile'
        }]
      }
      cards['shipping'] = {
        title: this.$t('dashboard.my-default-shipping'),
        content: `${this.getDefaultAddress(parseInt(this.user.default_shipping)) ? this.getDefaultAddress(parseInt(this.user.default_shipping)) : this.$t('dashboard.no_shipping_address')}`,
        ctas: [{
          label: this.$t('change'),
          to: 'myAddresses'
        }]
      }
      cards['billing'] = {
        title: this.$t('dashboard.my-default-billing'),
        content: `${this.getDefaultAddress(parseInt(this.user.default_billing)) ? this.getDefaultAddress(parseInt(this.user.default_billing)) : this.$t('dashboard.no_billing_address')}`,
        ctas: [{
          label: this.$t('change'),
          to: 'myAddresses'
        }]
      }
      return cards
    }
  },
  methods: {
    getDefaultAddress (id) {
      let addr = _.find(this.user.addresses, ['id', id])
      if (addr) {
        return `
          ${addr.firstname} ${addr.lastname}<br>
          ${addr.street.join(', ')}<br>
          ${addr.city}${addr.region ? ', ' + addr.region.region : ''}, ${addr.postcode}<br>
          ${addr.country_code}<br>
          ${(addr.telephone) ? `<a href="tel:${addr.telephone}" class="ff-link ff-link-primary"> T: ${addr.telephone}</a>` : ''}
        `
      } else {
        return false
      }
    }
  },
  components: {
    DashCard
  }
}
</script>
